<template>
  <main style="padding-top: 100px;">
    <div class="page_header border-bottom element_to_stick">
      <b-container>
        <b-row>
          <b-col md="12">
            <h1 v-if="!mailVerified">Verificare email...</h1>
            <h1 v-if="mailVerified && !error">Adresa de email a fost verificata!</h1>
            <h1 v-if="error">{{ error }}</h1>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </main>
</template>

<script>
import axios from "axios";
export default {
  name: 'VerifyEmail',
  data() {
    return {
      isBusy: false,
      token: this.$route.params.token,
      mailVerified: false,
      error: false
    }
  },
  methods: {
    verifyEmail() {
      const postData = {
        token: this.token,
      }
      const apiUrl = process.env.VUE_APP_API_URL;
      axios.post(apiUrl + '/public/verify_email', postData).then(response => {
        this.mailVerified = true
      }).catch(() => {
        this.mailVerified = true
        this.error = 'Adresa de email nu poate fi verificata. Este posibil ca token-ul sa fi expirat.'
      })
    }
  },
  mounted() {
    this.verifyEmail()
  }
}
</script>

